import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { MultiPincodeModalComponent } from './modals/multi-pincode-modal/multi-pincode-modal.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SkFileUploaderComponent } from './components/sk-file-uploader/sk-file-uploader.component';
import { SkImageComponent } from './components/sk-image/sk-image.component';
import { FileUploadModule } from 'ng2-file-upload';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { SkSplitInputComponent } from './components/sk-split-input/sk-split-input.component';
import { OtpModalComponent } from './modals/otp-modal/otp-modal.component';
import { GalleryComponent } from './modals/gallery/gallery.component';
import { StoreCardComponent } from './components/store-card/store-card.component';
import { StoreClickDirective } from './directives/store-click.directive';
import { ShareStoreDirective } from './directives/share-store.directive';



@NgModule({
  declarations: [
    HeaderComponent,
    MultiPincodeModalComponent,
    SkFileUploaderComponent,
    SkImageComponent,
    NumbersOnlyDirective,
    SkSplitInputComponent,
    OtpModalComponent,
    GalleryComponent,
    StoreCardComponent,
    StoreClickDirective,
    ShareStoreDirective
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule
  ],
  entryComponents: [
    MultiPincodeModalComponent,
    OtpModalComponent,
    GalleryComponent
  ],
  exports: [
    HeaderComponent,
    SkFileUploaderComponent,
    SkImageComponent,
    NumbersOnlyDirective,
    SkSplitInputComponent,
    StoreCardComponent,
    StoreClickDirective,
    ShareStoreDirective
  ]
})
export class SharedModule { }

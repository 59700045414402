import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppNavService } from '../providers/app-nav.service';
import { CommonService } from '../providers/common.service';

@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss'],
})
export class StoresComponent implements OnInit {

  stores: Array<any>;

  autoClickKey: string;

  constructor(
    private router: ActivatedRoute,
    private commonService: CommonService,
    private appNav: AppNavService
  ) {
    this.stores = [];
    this.autoClickKey = '';
    router.queryParams.subscribe(
      (data) => {
        this.autoClickKey = data.id;
      }
    );
  }

  ngOnInit() {
    this.stores = this.commonService.getStoreList();
  }

  gotoHome() {
    this.appNav.nav('/init', {
      replaceUrl: true
    });
  }

}

import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appShareStore]'
})
export class ShareStoreDirective {

  @Input() store: any = {};

  @HostListener('click', ['$event']) onClick(ev:Event) {
    ev.stopPropagation();
    this.share();
  }

  constructor() { }

  share() {
    const url = encodeURIComponent(`https://smart.storeking.in/#/stores?id=${this.store.key}`);
    let msg = `Hey checkout StoreKing Smart Store: ${this.store.name} at ${this.store.area} ${url}`
    const shareUrl = "https://api.whatsapp.com/send" + "?text=" + msg;
    window.open(shareUrl, '_system');
  }

}

import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { ToastOptions } from '@ionic/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private toast;

  constructor(
    private toastCtrl: ToastController
  ) { }

  async show(msg: string, type = '', config?: ToastOptions) {
    const defaultConfig = {
      duration: 5000,
      color: type === 'error' ? 'danger' : 'success',
      message: msg,
      position: 'top',
      cssClass: 'app-toast'
    };
    this.toast = await this.toastCtrl.create(Object.assign({}, defaultConfig, config));
    this.toast.present();
  }
  
}

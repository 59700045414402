import { Injectable, ElementRef } from '@angular/core';
import { AjaxService } from './ajax.service';
import { ConfigService } from './config.service';
import { SkStorageService } from './sk-storage.service';
import { AppModalService } from './app-modal.service';
import { OtpModalComponent } from '../shared/modals/otp-modal/otp-modal.component';

declare const google;

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private apiEndPoint: string;
  private apiVersion: string;

  private headerCategories: Array<object>;

  constructor(
    private ajax: AjaxService,
    private config: ConfigService,
    private skStorage: SkStorageService,
    private appModal: AppModalService
  ) {
    this.apiEndPoint = config.getConfig().API;
    this.apiVersion = config.getConfig().API_VERSION;
  }

  isDesktopView() {
    return window.innerWidth >= this.config.getConfig().DESKTOP_MIN_WIDTH ? true : false;
  }

  gridViewSplit(data: {}[], elementsPerRow: number) {
    const newArr = [];
    for (let i = 0; i < data.length; i += elementsPerRow) {
      newArr.push(data.slice(i, i + elementsPerRow));
    }
    return newArr;
  }

  roundedBYDecimalPlace(val: number, decimalPlaces: number) {
    const denominator = Math.pow(10, decimalPlaces);
    const roundedNumber = Math.round(val * denominator) / denominator;
    return roundedNumber;
  }


  decodeJwt(token) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    } catch (e) {
      return {};
    }
  }

  isJwtTokenExpired(token) {
    const decode = this.decodeJwt(token);
    if (decode.exp < (new Date().getTime() / 1000)) {
      return true;
    } else {
      return false;
    }
  }

  plotInMap(el: ElementRef, lat: number, lng: number, zoom = 19, options: any = {}) {
    const o = {
      center: { lat: 1 * lat, lng: 1 * lng },
      zoom,
      draggable: typeof options.draggable !== 'undefined' ? options.draggable : false,
      disableDefaultUI: typeof options.disableDefaultUI !== 'undefined' ? options.disableDefaultUI : false
    };
    const mapEl = new google.maps.Map(el.nativeElement as HTMLElement, o);
    const map = new google.maps.Marker({
      position: { lat, lng },
      map: mapEl,
      draggable: false
    });
    return mapEl;
  }

  isValidPan(val) {
    return /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(val);
  }

  getGstPattern() {
    return /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  }

  isValidGst(val) {
    return this.getGstPattern().test(val);
  }

  isValidAadhar(val) {
    return /^[0-9]{12,12}$/.test(val);
  }

  validMobileNoPattern() {
    return /^[0-9]{10,10}$/;
  }

  isValidMobileNo(mob) {
    const patt = /^[0-9]{10}$/;
    const inp = mob.toString();
    return patt.test(inp);
  }

  emailValidationPattern() {
    return /^(([^<>()\[\]\.,;:\s@\']+(\.[^<>()\[\]\.,;:\s@\']+)*)|(\'.+\'))@(([^<>()[\]\.,;:\s@\']+\.)+[^<>()[\]\.,;:\s@\']{2,})$/i;
  }

  isValidEmail(val, allowSkEmail = true) {
    const v = this.emailValidationPattern().test(val);
    if (!v) {
      return false;
    }
    if (allowSkEmail === false) {
      return !/@storeking\./.test(val);
    } else {
      return v;
    }
  }

  verifyEmail(params) {
    return this.ajax.request(this.apiEndPoint + '/' + this.apiVersion + '/verifyEmail', 'POST', params || {});
  }

  emailCodeVerify(params) {
    return this.ajax.request(this.apiEndPoint + '/' + this.apiVersion + '/emailVerifyByCode', 'POST', params || {});
  }

  resendEmailVerificationCode(params) {
    return this.ajax.request(this.apiEndPoint + '/' + this.apiVersion + '/verifyEmailRetry', 'POST', params || {});
  }

  getSkTermsAndCondition(params?) {
    return this.ajax.request(this.apiEndPoint + '/config/termsandconditions', 'GET', params || {});
  }

  uploadToS3Bucket(module, id, params) {
    let url = this.apiEndPoint + '/asset/' + this.apiVersion + '/s3/upload';
    if (module) {
      url += '?module=' + encodeURIComponent(module);
    }
    if (id) {
      url += '&referenceId=' + id;
    }
    return this.ajax.request(url, 'POST', params || {});
  }

  getAddressOnPincode(pincode) {
    const params = {
      page: 1,
      count: 100,
      filter: { pincode }
    };
    return this.ajax.request(this.apiEndPoint + '/utilities/' + this.apiVersion + '/pincodeImport', 'GET', params || {});
  }

  getDocs(type, params?: object) {
    return this.ajax.request(this.apiEndPoint + '/utilities/getSignUpConfigurationList/' + type, 'GET', params || {});
  }

  prepareAddressProofKeys(docName, docRefNo, docAttachmentId, docAttachementSide?) {
    const o: any = {
      addressProof: docName,
      addressProofNo: docRefNo,
      addressProofFile: docAttachmentId
    };
    if (docAttachementSide) { o.documentFace = docAttachementSide; }
    return o;
  }

  preparePhotoProofKeys(docName, docRefNo, docAttachmentId, docAttachementSide?) {
    const o: any = {
      photoID: docName,
      photoIDNo: docRefNo,
      photoIDFile: docAttachmentId
    };
    if (docAttachementSide) { o.documentFace = docAttachementSide; }
    return o;
  }

  validateMob(mob) {
    return this.ajax.request(this.apiEndPoint + '/utilities/' + this.apiVersion + '/fos/validatemobile/' + mob, 'GET');
  }

  validateOtp(params) {
    return this.ajax.request(this.apiEndPoint + '/utilities/' + this.apiVersion + '/fos/validatemobile/validateOtp', 'POST', params);
  }

  retryOtp(otpId) {
    return this.ajax.request(this.apiEndPoint + '/utilities/' + this.apiVersion + '/fos/validatemobile/retryOtp/' + otpId, 'GET');
  }

  doOnboard(params) {
    return this.ajax.request(this.apiEndPoint + '/utilities/' + this.apiVersion + '/fos/fosSignUp', 'POST', params);
  }

  async openOtp(data, resendCallback, validateCallback, callback) {
    const m = await this.appModal.show(OtpModalComponent, {
      resendOtp: resendCallback,
      validateOtp: validateCallback,
      otpCount: 6,
      data
    }, {
      cssClass: 'centered-screen-modal transparent-modal'
    });
    const d: any = await m.onWillDismiss();
    const r = d.data;
  }

  getLocalRegData() {
    let l: any = {};
    try {
      l = JSON.parse(this.skStorage.get('frm') || '{}');
    } catch (e) {
      l = {};
    }
    return l;
  }

  canViewOnboardPage() {
    const l = this.getLocalRegData();
    if (!l || !l.mobileNo) {
      return false;
    } else {
      return true;
    }
  }

  pincodeMulitpleDataFilter(pincodeData) {
    const temp = [];
    return (pincodeData || []).filter(v => {
      const s = v.state + ':' + v.district + ':' + v.town;
      if (temp.indexOf(s) === -1) {
        temp.push(s);
        return true;
      } else {
        return false;
      }
    });
  }

  register(params) {
    return this.ajax.request(this.apiEndPoint + '/utilities/' + this.apiVersion + '/smartStore/contact', 'POST', params);
  }

  getStoreList() {
    return [{
      img: '',
      name: 'Royal SuperMarket',
      area: 'Panchanachalli, Kadur, Karnataka',
      key: 'rs',
      maxImgs: 5,
      videos: 0,
      fp: 2
    }, {
      img: '',
      name: 'S Mart Supermarket',
      area: 'Vemagal, Chikkaballapura, Karnataka',
      key: 'sm',
      maxImgs: 5,
      videos: 2,
      fp: 2
    }, {
      img: '',
      name: 'Hanuman SuperMarket',
      area: 'Gandasi, Hassan, Karnataka',
      key: 'hs',
      maxImgs: 6,
      videos: 1,
      fp: 2
    }, {
      img: '',
      name: 'Sri Ram Supermarket',
      area: 'Madangeri, Ankola, Uttar Karnataka',
      key: 'sram',
      maxImgs: 5,
      videos: 2,
      fp: 2
    }, {
      img: '',
      name: 'Akheel Trading Company',
      area: 'Vkota, Chittor, AndraPradhesh',
      key: 'atc',
      maxImgs: 4,
      videos: 1,
      fp: 2
    }, {
      img: '',
      name: 'Friend\'s Supermarket',
      area: 'Davangere, Karnataka.',
      key: 'friends',
      maxImgs: 6,
      videos: 0,
      fp: 2
    }, {
      img: '',
      name: 'S N Super Market',
      area: 'Arsikere, Hassan, Karnataka.',
      key: 'snar',
      maxImgs: 4,
      videos: 0,
      fp: 2
    }, {
      img: '',
      name: 'Active Store',
      area: 'Thiruvangoor, Kerala',
      key: 'activeStore',
      maxImgs: 4,
      videos: 0,
      fp: 2
    }, {
      img: '',
      name: 'NILANJANA Supermarket',
      area: 'Pandavapura, Mandya, Karnataka',
      key: 'ns',
      maxImgs: 4,
      videos: 0,
      fp: 2
    }, {
      img: '',
      name: 'Jayam Traders',
      area: 'Dharampuri, Tamil Nadu',
      key: 'jayamTraders',
      maxImgs: 3,
      videos: 0,
      fp: 2
    }, {
      img: '',
      name: 'JK Smart Super Store',
      area: 'Hassan, Karnataka',
      key: 'jskSmartSuperStore',
      maxImgs: 4,
      videos: 0,
      fp: 2
    }, {
      img: '',
      name: 'Sunrise Supermarket',
      area: 'Neelmangala, Karnataka',
      key: 'sunrise',
      maxImgs: 2,
      videos: 0,
      fp: 2
    }, {
      img: '',
      name: 'VeeraShivam Enterprises',
      area: 'Chikodi, Belagavi',
      key: 'veeraShivam',
      maxImgs: 2,
      videos: 0,
      fp: 2
    }, {
      img: '',
      name: 'JKS Traders',
      area: 'Birur, Chikmagalur, Karnataka',
      key: 'jks_traders',
      maxImgs: 4,
      videos: 0,
      fp: 2
    }, {
      img: '',
      name: 'Neeharika Mart',
      area: 'Nelamangala, Bangalore Rural',
      key: 'niharika',
      maxImgs: 4,
      videos: 0,
      fp: 2
    }, {
      img: '',
      name: 'M Mart',
      area: 'Dibburahalli, Chikkaballapur',
      key: 'mmart',
      maxImgs: 4,
      videos: 0,
      fp: 2
    }, {
      img: '',
      name: 'Shiva Supermarket',
      area: 'Marandahalli, Dharmapuri, TamilNadu',
      key: 'shiva-supermarket',
      maxImgs: 3,
      videos: 0,
      fp: 2
    }, {
      img: '',
      name: 'Koppa Supermart',
      area: 'Koppa',
      key: 'koppa-supermart',
      maxImgs: 3,
      videos: 0,
      fp: 2
    }, {
      img: '',
      name: 'Manoj Super Mart',
      area: 'Pavagoda, Tumkur, Karnataka',
      key: 'manoj',
      maxImgs: 4,
      videos: 1,
      fp: 2
    }, {
      img: '',
      name: 'Amma Supermarket',
      area: 'Hunasuru, Mysore, Karnataka',
      key: 'amma',
      maxImgs: 6,
      videos: 0,
      fp: 2
    }, {
      img: '',
      name: 'V4 Superstore',
      area: 'Hanbal, Sakleshpura, Hassan, Karnataka',
      key: 'v4store',
      maxImgs: 4,
      videos: 0,
      fp: 1
    }, {
      img: '',
      name: 'Eldorado Supermarket',
      area: 'KGF, Karnataka',
      key: 'eldorado',
      maxImgs: 4,
      videos: 0,
      fp: 2
    }, {
      img: '',
      name: 'Manish Enterprises',
      area: 'KM Doddi,Mandya,Karnataka',
      key: 'manish',
      maxImgs: 5,
      videos: 0,
      fp: 2
    }, {
      img: '',
      name: 'Janatha Hyper Market',
      area: 'Shanivarasanthe, Kodagu, Karnataka',
      key: 'jantha-hyper-market',
      maxImgs: 5,
      videos: 0,
      fp: 2
    }, {
      img: '',
      name: 'Kalmeshwar Super Market',
      area: 'Gulbarga Bijapur Road, Devara Hipparagi, Karnataka',
      key: 'kalmeshwar',
      maxImgs: 3,
      videos: 0,
      fp: 2
    }, {
      img: '',
      name: 'Sulake Smartstore',
      area: 'Shettihalli Main Road Tumkur, Karnataka',
      key: 'sulake',
      maxImgs: 4,
      videos: 0,
      fp: 2
    }, {
      img: '',
      name: 'SDM Supermarket',
      area: 'Bidadi, Ramanagar, Karnataka',
      key: 'sdm',
      maxImgs: 4,
      videos: 0,
      fp: 2
    }, {
      img: '',
      name: 'Shiva Big Bazar',
      area: 'Mandya, Karnataka',
      key: 'shiva-mandya',
      maxImgs: 4,
      videos: 0,
      fp: 2
    }, {
      img: '',
      name: 'Srinivasa Store',
      area: 'Halebeedu Hassan',
      key: 'srinivasastore',
      maxImgs: 5,
      videos: 0,
      fp: 2
    }, {
      img: '',
      name: 'MES Mart',
      area: 'Thyamagondlu, Bangalore, Karnataka',
      key: 'mesmart',
      maxImgs: 9,
      videos: 0,
      fp: 2
    }, {
      img: '',
      name: 'Sri Vinayaga Traders',
      area: 'Virudhunagar, Tamil Nadu- 626001',
      key: 'vinayaga-traders',
      maxImgs: 7,
      videos: 0,
      fp: 3
    }, {
      img: '',
      name: 'N M SUPERMARKET',
      area: 'Mysore, Karnataka, 571130',
      key: 'nm-supermarket',
      maxImgs: 7,
      videos: 0,
      fp: 3
    }, {
      img: '',
      name: 'Pehal Super Store',
      area: 'Jindal Nagar, Karnataka, 583101',
      key: 'pehal',
      maxImgs: 5,
      videos: 0,
      fp: 4
    }, {
      img: '',
      name: 'R S MART',
      area: 'Nellore Andhra Pradesh, 524304',
      key: 'rs-mart',
      maxImgs: 7,
      videos: 0,
      fp: 1
    }, {
      img: '',
      name: 'Sri Nanjundeshwara Super Market',
      area: 'Kestur, Mandya, Karnataka, 571476',
      key: 'nanjundeshwara',
      maxImgs: 14,
      videos: 0,
      fp: 1
    }, {
      img: '',
      name: 'Sri Annapoorneshwari Enterprises',
      area: 'Challakare, Chitradurga, Karnataka, 577522',
      key: 'annapoorneshwari',
      maxImgs: 4,
      videos: 0,
      fp: 1
    }, {
      img: '',
      name: 'SM Super Market',
      area: 'Hirisave, Hassan, Karnataka, 573124',
      key: 'sm-supermarket',
      maxImgs: 12,
      videos: 0,
      fp: 2
    }, {
      img: '',
      name: 'Sri Nanjundeshwara Provision Store',
      area: 'Kudur, Ramnagar, Karnataka, 561101',
      key: 'nanjundeshwara-provision-store',
      maxImgs: 6,
      videos: 0,
      fp: 1
    }, {
      img: '',
      name: 'GT Super Market',
      area: 'Kethiganahalli Road, Ramnagar, Karnataka',
      key: 'gt-supermarket',
      maxImgs: 5,
      videos: 0,
      fp: 1
    }, {
      img: '',
      name: 'JS Mart',
      area: 'Vinayaka Complex Main Road, Bhutanagudi, Bhadravathi',
      key: 'js-mart-bhardravati',
      maxImgs: 7,
      videos: 0,
      fp: 1
    }, {
      img: '',
      name: 'Green Leaf Feast mart',
      area: '',
      key: 'green-leaf',
      maxImgs: 10,
      videos: 0,
      fp: 1
    }, {
      img: '',
      name: 'JS Mart Tarikere',
      area: '',
      key: 'js-mart-tarikere',
      maxImgs: 8,
      videos: 0,
      fp: 1
    }, {
      img: '',
      name: 'SMB Smart Store',
      area: '',
      key: 'smb',
      maxImgs: 8,
      videos: 0,
      fp: 1
    }].reverse();
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-multi-pincode-modal',
  templateUrl: './multi-pincode-modal.component.html',
  styleUrls: ['./multi-pincode-modal.component.scss'],
})
export class MultiPincodeModalComponent implements OnInit {

  @Input() data: { pincodeData } = { pincodeData: [] };

  formData = {
    loc: ''
  };

  locs = [];

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    const t = setTimeout(() => {
      clearTimeout(t);
      this.locs = this.data.pincodeData || [];
    }, 500);
  }

  closeModal(status?) {
    this.modalCtrl.dismiss({
      status,
      selectedData: this.formData.loc
    });
  }

  onLocChange() {
    if (this.formData.loc) {
      this.closeModal('applied');
    }
  }

}

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ConfigService } from 'src/app/providers/config.service';

@Component({
  selector: 'app-sk-image',
  templateUrl: './sk-image.component.html',
  styleUrls: ['./sk-image.component.scss'],
})
export class SkImageComponent {

  @ViewChild('imgRef') imgRef;

  width;
  height;
  imgSrc;
  ext;
  imgPath;
  doLazyload;
  ignoreSize;

  @Input()
  set config(v) {
    this.width = v.width || undefined;
    this.height = v.height || undefined;
    this.imgSrc = v.asset || '';
    this.ext = v.ext || '';
    this.ignoreSize = v.ignoreSize || false;
    this.doLazyload = (typeof v.lazyload === 'undefined') ? true : v.lazyload;
    this.updateImage();
  }

  constructor(
    private skConfig: ConfigService
  ) { }

  getExtension() {
    const ext = this.ext || 'jpg';
    return ext;
  }

  updateImage() {
    const width = this.width;
    const height = this.height;
    const imgSrc = this.imgSrc;
    const ext = this.getExtension();

    if (!imgSrc) {
      this.imgPath = this.getDefaultImg();
    } else if (typeof width !== 'undefined') {
      this.imgPath = this.skConfig.getConfig().BASE_URL_IMAGE + '/' + imgSrc + '.' + ext + '?size=' + width;
      if (height) {
        this.imgPath += 'x' + height;
      }
    } else {
      this.imgPath = this.skConfig.getConfig().BASE_URL_IMAGE + '/' + imgSrc + '.' + ext;
      if (!this.ignoreSize) {
        this.imgPath += '?size=200';
      }
    }

    if (imgSrc.indexOf('base64') !== -1) { this.imgPath = imgSrc; }
  }

  onError() {
    this.imgRef.nativeElement.src = this.getDefaultImg();
  }

  getDefaultImg() {
    // let location = window['location'];
    // let curUrl = location['protocol'] + '//' + location['host'] + '/'
    // return curUrl + 'assets/imgs/placeholder.png';
    return './assets/imgs/placeholder.jpg';
  }

}

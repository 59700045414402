import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ComponentRef } from '@ionic/core';

@Injectable({
  providedIn: 'root'
})
export class AppModalService {

  constructor(
    private modalController: ModalController
  ) { }

  async show(comp: ComponentRef, data, opts?) {
    const defaultOpts: any = {
      component: comp
    };
    if (data) {
      defaultOpts.componentProps = { data };
    }
    const modal = await this.modalController.create(Object.assign({}, defaultOpts, opts || {}));
    await modal.present();
    return modal;
  }
}

import { Directive, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AppModalService } from 'src/app/providers/app-modal.service';
import { CommonService } from 'src/app/providers/common.service';
import { GalleryComponent } from '../modals/gallery/gallery.component';

@Directive({
  selector: '[appStoreClick]'
})
export class StoreClickDirective implements OnChanges {

  @Input() store: any = {};

  @Input() autoClickKey = -1;

  @HostListener('click') onClick() {
    this.onTap();
  }

  constructor(
    private appModal: AppModalService,
    private commonService: CommonService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.autoClickKey && changes.autoClickKey.currentValue === this.store.key){
      this.onTap();
    }
  }

  async onTap() {
    const store = this.store;

    if (store.openShortly) {
      return;
    }
    let data = [];
    data.push({
      type: 'img',
      src: './assets/imgs/stores/' + store.key + '/main.jpg'
    });
    for (let i = 1; i <= store.maxImgs; i++) {
      data.push({
        type: 'img',
        src: './assets/imgs/stores/' + store.key + '/' + i + '.jpg'
      });
    }
    for (let i = 1; i <= store.videos; i++) {
      data.push({
        type: 'video',
        src: './assets/imgs/stores/' + store.key + '/v' + i + '.mp4',
        poster: './assets/imgs/stores/' + store.key + '/main.jpg'
      });
    }
    for (let i = 1; i <= store.fp; i++) {
      data.push({
        type: 'img',
        src: './assets/imgs/stores/' + store.key + '/fp/' + i + '.jpg',
      });
    }
    const m = await this.appModal.show(GalleryComponent, {
      data: data,
      title: store.name,
      subTitle: store.area,
      store: this.store
    }, {
      cssClass: !this.commonService.isDesktopView() ? 'centered-screen-modal transparent-modal' : 'gallery-modal'
    });
  }

}

import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppNavService {

  constructor(
    private router: Router
  ) { }

  nav(path: string, opts?: NavigationExtras) {
    const a = path.split('/');
    if (a[0] === '/') {
      a.splice(0, 1);
    }
    this.router.navigate(a, opts);
  }
  
}

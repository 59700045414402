import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastService } from 'src/app/providers/toast.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-otp-modal',
  templateUrl: './otp-modal.component.html',
  styleUrls: ['./otp-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OtpModalComponent implements OnInit {

  @Input() data: any = {};

  otpData;
  pageConfig = new PageConfig();
  otpForm: FormGroup;
  otpRepeat;
  otpVal;
  otpInputValue;
  resetInput: boolean;

  constructor(
    public toastr: ToastService,
    private viewCtrl: ModalController
  ) {
    this.resetInput = false;
  }

  ngOnInit() {
    this.pageInit();
  }

  pageInit() {
    this.otpData = this.data.data;

    // FOR DEFAULT OTP COUNT
    if (typeof this.otpData.otpCount === 'undefined') {
      this.otpData.otpCount = 6;
    }

    this.otpInputValue = [];
    this.pageConfig.resend = this.data.resendOtp;
    this.pageConfig.validate = this.data.validateOtp;
    this.otpRepeat = [];
    if (this.otpData.otpViewType !== 'input') {
      const count = this.otpData.otpCount;
      for (let i = 0; i < count; i++) {
        this.otpRepeat.push(i);
      }
    }
  }

  resendOtp() {
    this.otpVal = '';
    this.pageConfig.resend();

    // FOR CLEARING INPUT
    this.resetInput = true;
    const t = setTimeout(() => {
      clearTimeout(t);
      this.resetInput = false;
    }, 500);
  }

  getOtp(data) {
    this.otpVal = data.val;
  }

  cancel() {
    this.viewCtrl.dismiss();
  }

  validateOtp() {
    const otp = this.prepareOtp();
    if (!otp) {
      this.toastr.show('Please Enter OTP', 'error');
      return false;
    }
    if (!this.otpData.otpMinCount && otp.length < this.otpData.otpCount) {
      this.toastr.show('Please Enter Valid OTP', 'error');
      return false;
    }
    if (this.otpData.otpMinCount > otp.length) {
      this.toastr.show('Please Enter Valid OTP', 'error');
      return false;
    }
    const data = { otp };
    this.pageConfig.validate(data);
  }

  prepareOtp() {
    const otp = this.otpVal || '';
    return otp;
  }

}

class PageConfig {
  resend: any;
  validate: any;
}

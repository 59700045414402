import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private env = 'live';
  private apiVersion = 'v1';

  private server = {
    live: {
      host: 'https://apigw.storeking.in/api',
      asset: 'https://assets.storeking.in/api/asset/' + this.apiVersion,
      dashboard: 'https://csc.storeking.in/oldapp',
      domain: 'https://app.storeking.in',
      pgHdfcPartner: 'DPM46'
    },
    preprod: {
      host: 'https://preprod.storeking.in/apigateway/api',
      asset: 'https://preprod.storeking.in/api/asset/' + this.apiVersion,
      dashboard: 'https://preprod.storeking.in/app',
      domain: 'https://preprod.storeking.in/appv3',
      pgHdfcPartner: 'DPM45'
    },
    qa: {
      host: 'https://qa.storeking.in/apigateway/api',
      asset: 'https://qa.storeking.in/api/asset/' + this.apiVersion,
      // asset: 'https://assets.storeking.in/api/asset/' + this.apiVersion,
      dashboard: 'https://qa.storeking.in/app',
      domain: 'https://qa.storeking.in/newappui'
    },
    uat: {
      host: 'https://uat.storeking.in/apigateway/api',
      asset: 'https://uat.storeking.in/api/asset/' + this.apiVersion,
      // asset: 'https://assets.storeking.in/api/asset/' + this.apiVersion,
      dashboard: 'http://uat.storeking.in/app',
      domain: 'https://uat.storeking.in/newappui',
      pgHdfcPartner: 'DPM44'
    },
    dev: {
      host: 'https://dev.storeking.in/apigateway/api',
      asset: 'https://dev.storeking.in/api/asset/' + this.apiVersion,
      // asset: 'https://assets.storeking.in/api/asset/' + this.apiVersion,
      dashboard: 'http://dev.storeking.in/app',
      domain: 'https://dev.storeking.in/newappui',
      pgHdfcPartner: 'DPM44'
    }
  };

  private config = {
    APP_VERSION: '1.0.1',
    API: this.server[this.env].host,
    APILocal: this.server[this.env].HOST,
    ASSET: this.server[this.env].asset,
    ASSET_UPLOAD: this.server[this.env].assetUpload || this.server[this.env].asset,
    DASHBOARD: this.server[this.env].dashboard,
    BASE_URL_IMAGE: this.server[this.env].asset,
    DOMAIN: this.server[this.env].domain,
    API_VERSION: this.apiVersion,
    KPB_CATEGORY_ID: 'C4307',
    HPB_CATEGORY_ID: 'C3558',
    PG_HDFC_PARTNER: this.server[this.env].pgHdfcPartner,
    BANNERS: {
      HOME_PAGE_BANNER_1: 'BANNERS-10002',
      HOME_PAGE_BRAND_BANNER: 'BANNERS-10028'
    },
    DESKTOP_MIN_WIDTH: 1025,
    CSC_REDIRECT: 'https://vle.cscestore.in'
  };

  constructor() { }

  getConfig() {
    return this.config;
  }

}

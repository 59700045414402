import { Component, OnInit, Output, Input, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-sk-split-input',
  templateUrl: './sk-split-input.component.html',
  styleUrls: ['./sk-split-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SkSplitInputComponent implements OnInit {

  @Output() doneCallback = new EventEmitter();
  @Input() limit = 5;
  @Input() idPrefix = 'inp_';

  @Input() set resetInp(val) {
    if (val === true) {
      this.inps.forEach((e, k) => {
        e.val = '';
        const el = document.getElementById(this.idPrefix + k);
        if (k === 0) {
          el.removeAttribute('readonly');
        } else {
          el.setAttribute('readonly', '');
        }
      });
    }
  }

  inps = [];

  constructor() { }

  ngOnInit() {
    for (let i = 0; i < this.limit; i++) {
      this.inps.push({
        val: ''
      });
    }

    const t = setTimeout(() => {
      clearTimeout(t);
      this.inps.forEach((e, k) => {
        try {
          const el = document.getElementById(this.idPrefix + k);
          if (k === 0) {
            el.focus();
          } else {
            el.setAttribute('readonly', '');
          }
        } catch (e) { }
      });
    }, 500);

  }


  onKeyUp(e, index) {
    // on backspace
    if (e.which === 8) {
      const prevIndex = index - 1;
      if (prevIndex >= 0) {
        const prevEl = document.getElementById(this.idPrefix + prevIndex);
        prevEl.removeAttribute('readonly');
        prevEl.focus();
      }
    } else if (!/^[0-9]{1,1}$/.test(e.target.value)) { /*FOR INVALID INPUTS */
      this.inps.forEach((v, k) => {
        if (k === index) { v.val = ''; }
      });
      e.preventDefault();
    } else {
      /*MOVING TO NEXT INPUT*/
      const nextIndex = index + 1;
      if (nextIndex < this.inps.length && nextIndex > 0) {
        const curEl = document.getElementById(this.idPrefix + index);
        curEl.setAttribute('readonly', '');
        const nextEl = document.getElementById(this.idPrefix + nextIndex);
        nextEl.removeAttribute('readonly');
        nextEl.focus();
      }
    }
    this.triggerOnDone();
  }

  triggerOnDone() {
    const v = this.getInpValue();
    if (v.length === this.limit) {
      this.doneCallback.emit({
        val: v
      });
    } else {
      this.doneCallback.emit({
        val: v,
        isValid: false
      });
    }
  }

  getInpValue() {
    let str = '';
    this.inps.forEach(e => {
      if (typeof e.val === 'number') { str += e.val; }
    });
    return str;
  }

}

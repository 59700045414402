import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { LoadingOptions } from '@ionic/core';

@Injectable({
  providedIn: 'root'
})
export class SkLoaderService {

  loader;

  constructor(
    private loadingCtrl: LoadingController
  ) { }

  async show(config?: LoadingOptions) {
    const defaultConfig = {
      message: 'Please wait...'
    };
    this.loader = await this.loadingCtrl.create(Object.assign({}, defaultConfig, config || {}));
    await this.loader.present();
    return this.loader;
  }

  hide() {
    if (this.loader) { this.loader.dismiss().then().catch((e) => { }); }
  }
}

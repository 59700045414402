import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SkStorageService {

  constructor() { }

  set(key, value) {
    let v;
    if (typeof value === 'object') {
      v = JSON.stringify(value);
    } else {
      v = value;
    }
    window.localStorage.setItem(key, v);
  }

  get(key) {
    if (window.localStorage[key]) {
      return window.localStorage.getItem(key);
    } else {
      return null;
    }
  }

  clear() {
    window.localStorage.clear();
  }

  remove(key) {
    if (window.localStorage[key]) {
      delete window.localStorage[key];
    }
  }
}

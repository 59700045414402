import { Component, OnInit, ElementRef, ViewChild, Output, Input, EventEmitter } from '@angular/core';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { ConfigService } from 'src/app/providers/config.service';
import { SkLoaderService } from 'src/app/providers/sk-loader.service';

@Component({
  selector: 'app-sk-file-uploader',
  templateUrl: './sk-file-uploader.component.html',
  styleUrls: ['./sk-file-uploader.component.scss'],
})
export class SkFileUploaderComponent implements OnInit {

  @ViewChild('fileInp') fileInp: ElementRef;

  @Output() uploadCallback = new EventEmitter();
  @Input() allowedMimeType = ['image/jpg', 'image/jpeg', 'image/png'];
  @Input() uploadUrl;
  @Input() maxFileSize = 5;

  uploader;
  assetId;
  defaultAssetUrl;

  constructor(
    public skConfigProvider: ConfigService,
    public skLoaderProvider: SkLoaderService
  ) { }

  ngOnInit() {
    this.defaultAssetUrl = this.skConfigProvider.getConfig().ASSET_UPLOAD + '/upload';

    const options: FileUploaderOptions = {
      url: this.defaultAssetUrl,
      method: 'POST',
      maxFileSize: 1024 * 1024 * this.maxFileSize,
    };

    // if (this.authProvider.isUserLoggedIn()) {
    //   options.headers = [{
    //     name: 'Authorization',
    //     value: 'JWT ' + this.authProvider.getLoggedInToken()
    //   }];
    // }

    this.uploader = new FileUploader(options);

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      this.uploader.queue[0] = file;
    };

    this.uploader.onBeforeUploadItem = () => {
      this.skLoaderProvider.show();
    };

    this.uploader.onSuccessItem = (file, resp) => {
      this.skLoaderProvider.hide();
      const r = JSON.parse(resp);
      this.uploader.clearQueue();
      this.assetId = r._id || '';
      this.invokeCallback(file, r, 'success', '');
    };

    this.uploader.onErrorItem = (file, resp) => {
      this.skLoaderProvider.hide();
      this.invokeCallback(file, resp, 'error', 'Failed to upload');
    };

    this.uploader.onWhenAddingFileFailed = (item: any, filter: any) => {
      let msg;
      switch (filter.name) {
        case 'fileSize':
          msg = 'File size is too large';
          break;
        default: msg = '';
      }
      this.invokeCallback('', {}, 'error', msg);
    };
  }

  fileSelected(e) {
    const f = e[0] || e;
    if (this.allowedMimeType.length > 0 && f.type && this.allowedMimeType.indexOf(f.type) === -1) {
      this.invokeCallback(f, {}, 'error', 'Invalid File Type');
      return false;
    }

    const url = this.prepareUrlOnSelectedFileType(f.name);
    this.uploader.setOptions({ url });
    if (this.uploader.queue.length > 0) { this.uploader.queue[0].upload(); }
  }

  invokeCallback(file, resp, status, msg) {
    this.fileInp.nativeElement.value = null;
    this.uploadCallback.emit({ file, resp, status, msg });
  }

  prepareUrlOnSelectedFileType(file) {
    const img = ['jpg', 'jpeg', 'png'];
    const docs = ['pdf', 'zip', 'doc'];
    const name = file || '';
    let url = this.uploadUrl || this.defaultAssetUrl;
    const extension = name.substring(name.lastIndexOf('.') + 1);

    if (img.indexOf(extension) !== -1) {
      url += (/\?/.test(url)) ? '&type=image' : '?type=image';
    }

    if (docs.indexOf(extension) !== -1) {
      url += (/\?/.test(url)) ? '&type=doc' : '?type=doc';
    }

    return url;
  }

  // opens action sheet to select photo from camera or gallery, only for cordova
  openDialog() {
    this.fileInp.nativeElement.click();
  }

  onInpChange() {
    this.fileInp.nativeElement.value = null;
  }

}

import { Injectable } from '@angular/core';
import { forIn as _forIn } from 'lodash';
import { SkStorageService } from './sk-storage.service';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AjaxService {

  constructor(
    public http: HttpClient,
    public storage: SkStorageService
  ) { }

  request(url: string, type?: string, params?: object, config?: object) {
    const t = (type) ? type.toUpperCase() : 'GET';
    const headers = new HttpHeaders();

    const defaultConfig = {
      headers,
      params: {},
      body: {}
    };

    // PREPARING PARAMS
    if (params && Object.keys(params).length > 0) {

      let p = new HttpParams();
      _forIn(params, (v, k) => {
        p = p.append(k, (typeof v === 'object') ? JSON.stringify(v) : v);
      });

      if (t === 'GET') {
        defaultConfig.params = p;
      } else if (t === 'DELETE' || t === 'POST' || t === 'PUT' || t === 'PATCH') {
        defaultConfig.body = p;
      } else {
        defaultConfig.params = p;
      }
    }

    switch (t) {
      case 'GET': return this.http.get(url, defaultConfig);
      case 'POST': return this.http.post(url, params || {}, defaultConfig);
      case 'DELETE': return this.http.delete(url, defaultConfig);
      case 'PUT': return this.http.put(url, params || {}, defaultConfig);
      case 'PATCH': return this.http.patch(url, params || {}, defaultConfig);
      default: return this.http.get(url, defaultConfig);
    }
  }
  
}

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';
import { CommonService } from 'src/app/providers/common.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit, OnDestroy {

  @ViewChild('slides') slides: IonSlides;

  @Input() data: any = { data: [] };

  hideThumb = true;

  selected: any = {};

  isDesktop: boolean;

  interval: any;

  constructor(
    private commonService: CommonService,
    private modalCtrl: ModalController
  ) {
    this.isDesktop = this.commonService.isDesktopView();
  }

  ngOnInit() {

    this.selected = this.data.data[0];    

    const t = setTimeout(() => {
      clearTimeout(t);
      this.hideThumb = false;
      this.slides.update();
      this.slides.slideTo(0);
      this.runImages();
    }, 500);
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  onSelect(data, stopAutoSlide = false) {
    this.selected = data;
    if (stopAutoSlide) {
      clearInterval(this.interval);
    }
  }

  runImages() {
    const max = this.data.data.length - 1;
    let i = 0;
    this.interval = setInterval(() => {
      if (i == max) {
        i = 0;
      } else {
        i++;
      }
      if (this.data.data[i].type == 'video') {
        clearInterval(this.interval);
      }
      this.slides.slideTo(i);
      this.onSelect(this.data.data[i]);
    }, 5000);
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  prevSlide() {
    this.slides.slidePrev();
  }

  nextSlide() {
    this.slides.slideNext();
  }

}
